import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const RectangleButton = ({ iconLabel, link }) => {
  if (link) {
    return (
      <IconLink to={link}>
        <IconOuterDiv>{iconLabel}</IconOuterDiv>
      </IconLink>
    );
  } else {
    return <IconOuterDiv>{iconLabel}</IconOuterDiv>;
  }
};

const IconOuterDiv = styled.div`
  background-color: ${(props) => props.theme.color.orange};
  border-radius: 0.3rem;
  padding: 0.4rem 0.6rem;
  font-size: 0.7rem;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0.3rem;
  text-align: center;

  &:hover {
    background-color: ${(props) => props.theme.color.darkGrey};
    cursor: pointer;
  }
`;

const IconLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.color.white};
`;

export default RectangleButton;
