import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { toTitleCase } from "../../../shared/utils/toTitleCase";
import { getPostIcon } from "../../../shared/utils/getPostIcon";

const PostListItem = ({ id, icon, postTitle, postLink, postDate }) => {
  const iconInfo = icon ? icon.split(", ") : null;
  const getIcon = icon ? getPostIcon(iconInfo) : null;

  return (
    <React.Fragment>
      <PostListLink to={postLink}>
        <PostListItemOuterDiv id={id}>
          <div className="postIcon">{getIcon}</div>
          <div className="postTitle">{toTitleCase(postTitle)}</div>
          <div className="postDate">{postDate}</div>
        </PostListItemOuterDiv>
      </PostListLink>
    </React.Fragment>
  );
};

const PostListItemOuterDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0.9rem 0;
  width: 100%;
  border-bottom: 1.3px solid ${(props) => props.theme.color.lightGrey};

  .postTitle {
    font-size: 1em;
    margin-right: 1em;
  }

  .postIcon {
    margin-right: 1em;
    color: ${(props) => props.theme.color.orange};
  }

  &:hover {
    background-color: ${(props) => props.theme.color.mediumGrey};
  }

  .postItemInnerDiv {
    padding: 0 1rem 0 0;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .postDate {
    padding: 0 1rem 0 0;
    margin-left: auto;
    text-transform: uppercase;
    font-size: 0.8rem;
    color: grey;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    margin: auto;
    margin-top: 0.5em;

    .postTitle {
      font-size: 0.9;
    }

    .postTitle {
      font-size: 1em;
    }

    .postDate {
      padding: 0.2em;
      margin-left: 0;
      justify-content: center;
    }
  }
`;

const PostListLink = styled(Link)`
  text-decoration: none;
  width: 100%;
`;

export default PostListItem;
