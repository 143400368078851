import React from "react";
import styled from "styled-components";

const Title = ({ label, className }) => {
  return <h1 className={className}>{label}</h1>;
};

const StyledTitle = styled(Title)`
  text-align: center;
  font-size: 2rem;
`;

export default StyledTitle;
