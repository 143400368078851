import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "typicons.font/src/font/typicons.css";
import "remixicon/fonts/remixicon.css";

export const getPostIcon = (iconInfo) => {
  switch (iconInfo[0]) {
    case "fas":
    case "fab":
    case "far":
      return getFontAwesomeIcon(iconInfo);
    case "typicon":
      return getTypicon(iconInfo);
    case "remixicon":
      return getRemixIcon(iconInfo);
    default:
      return <i className={`devicon-${iconInfo[0]}-plain`}></i>;
  }
};

const getFontAwesomeIcon = (iconInfo) => {
  const iconType = iconInfo[0];
  const iconName = iconInfo[1];

  return (
    <FontAwesomeIcon
      alt={iconName + "icon"}
      icon={[iconType, iconName]}
      size="sm"
      fixedWidth
    />
  );
};

const getTypicon = (iconInfo) => {
  return <i className={`typcn typcn-${iconInfo[1]}`}></i>;
};

const getRemixIcon = (iconInfo) => {
  const name = iconInfo[1];
  const style = iconInfo[2];
  return <i class={`ri-${name}-${style}`}></i>;
};
