import React from "react";
import styled from "styled-components";

export const Divider = () => <StyledHR />;

const StyledHR = styled.hr`
  background: transparent;
  border-bottom: 1px dashed black;
  width: 100%;
  margin: 0 auto;

  &:before {
    top: 3px;
    left: 0;
    padding-right: 2em;
  }
`;

export default Divider;
