import React from "react";
import styled from "styled-components";

import RectangleButton from "../../shared/components/RectangleButton";
import PostListItem from "../../shared/components/PostListItem";
import { shortDateFormatter } from "../../shared/utils/dateFormatter";
import { toTitleCase } from "../../shared/utils/toTitleCase";
import Divider from "../../shared/components/Divider";

const PostList = ({
  posts,
  iconLabel = "View All",
  link,
  searchButtonLabel = "Search",
  searchLink,
  title,
  description,
  showDivider = false
}) => {
  const classPrefix = "post-list";
  const postsExist = posts && posts.length > 0;

  return (
    <PostListDiv>
      {showDivider && <Divider />}
      <div className={`${classPrefix}__inner-div`}>
        {title && (
          <div className={`${classPrefix}__header`}>
            <div className={`${classPrefix}__header-and-description`}>
              <PostListTitle>{toTitleCase(title)}</PostListTitle>
              {description && (
                <div className={`${classPrefix}__description`}>
                  {description}
                </div>
              )}
            </div>
            <div className="post-list__btns">
            {searchButtonLabel && (
              <div className={`${classPrefix}__rectangle-button`}>
                <RectangleButton iconLabel={searchButtonLabel} link={searchLink} />
              </div>
            )}
            {iconLabel && (
              <div className={`${classPrefix}__rectangle-button`}>
                <RectangleButton iconLabel={iconLabel} link={link} />
              </div>
            )}
            </div>
          </div>
        )}
      </div>
      {postsExist &&
        posts.map(({ id, fields, frontmatter }) => (
          <React.Fragment key={id}>
            <PostListItem
              key={id}
              icon={frontmatter.icon}
              postLink={fields.slug}
              postTitle={frontmatter.title}
              postDate={shortDateFormatter(frontmatter.date)}
            />
          </React.Fragment>
        ))}
    </PostListDiv>
  );
};

const PostListDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem 0;
  margin: 0 auto;
  width: 50%;

  i {
    color: ${props => props.theme.color.orange};
  }

  a {
    color: ${props => props.theme.color.defaultText};
    text-decoration: none;
    font-weight: 700;

    &:hover {
      color: ${props => props.theme.color.orange};
      border-radius: 4px;
    }
  }

  .post-list__btns {
    display: flex;
    flex-direction: column;
  }

  .post-list__rectangle-button {
    a {
      color: ${props => props.theme.color.white};
      text-decoration: none;
      font-weight: 700;
    }
  }

  .post-list__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 0.5rem;
    margin-bottom: 0.1em;
  }

  .post-list__inner-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1.3px solid ${props => props.theme.color.lightGrey};
  }

  .post-list__description {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    font-style: italic;
    font-size: 0.8em;
    font-weight: 600;
    padding-bottom: 0.3em;
  }

  .post-list__header-and-description {
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  @media (max-width: 786px) {
    width: 80%;

    .post-list__btns {
      display: flex;
      flex-direction: row;
    }

    .post-list__header {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 1em;
    }

    .post-list__description {
      padding-bottom: 1.5em;
    }

    .post-list__header-and-description {
      text-align: center;
    }
  }

  @media (max-width: 1024px) {
    width: 65%;
  }
`;

const PostListTitle = styled.h2`
  font-size: 1.5rem;
  color: ${props => props.theme.color.darkGrey};
`;

export default PostList;
